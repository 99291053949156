import { ColorIndicator } from '../../../../components';
import { getFormattedWaste } from '../../../../utils/dashboard';
import { isNil } from 'lodash';
import { dynamicSort } from '../../../../utils/sorting';

const getFormattedShare = (value) => {
  return parseFloat((value * 100).toFixed(2));
};

export const createRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        label: item.material,
      },
      {
        label: item.value ? getFormattedWaste(item.value) : '0',
      },
      {
        label: `${getFormattedShare(item.share)}%`,
      },
    ],
  }));
};

export const createFryerCyclesRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);
  let preparedRows = sortedRows.filter((item) => item.total || item.perHour);

  if (preparedRows.length > 0) preparedRows = withAdditionalRowsFryerCycles(preparedRows);

  return preparedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: !item.additional ? (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ) : (
          ''
        ),
      },
      {
        label: !item.additional ? (
          item.material
        ) : (
          <div className="custom-table__bold-text">{item.material}</div>
        ),
      },
      {
        label: !item.additional ? (
          getFormattedWaste(item.total)
        ) : (
          <div className="custom-table__bold-text">{getFormattedWaste(item.total)}</div>
        ),
      },
      {
        label: !item.additional ? (
          getFormattedWaste(item.perHour)
        ) : (
          <div className="custom-table__bold-text">{getFormattedWaste(item.perHour)}</div>
        ),
      },
    ],
  }));
};

export const createTaskDelayRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        label: item.material,
      },
      {
        label: item.totalDelay,
      },
      {
        label: item.highTaskDelay,
      },
      {
        label: item.lowTaskDelay,
      },
      {
        label: item.averageTaskDelay,
      },
      {
        label: item.delaysNumber,
      },
    ],
  }));
};

export const createStockOutRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        label: item.material,
      },
      {
        label: item.time,
      },
      {
        label: item.orderQuantity,
      },
    ],
  }));
};

export const createContainersRows = (rows, materials, sortModel) => {
  const sortedRows = dynamicSort(rows, sortModel.sortBy, sortModel.orderDirection);

  return sortedRows.map((item, index) => ({
    id: index,
    cells: [
      {
        paddingRight: 8,
        label: (
          <ColorIndicator color={materials.find((material) => material.value === item.material)?.color} />
        ),
      },
      {
        paddingRight: 0,
        paddingLeft: 4,
        isArrow: true,
      },
      {
        label: item.material,
      },
      {
        label: item.value ? getFormattedWaste(item.value) : '0',
      },
      {
        label: `${getFormattedShare(item.share)}%`,
      },
    ],
    items:
      item.containerShares?.map((share) => ({
        cells: [
          {
            label: '',
          },
          {
            label: '',
          },
          {
            label: share.containerName,
          },
          {
            label: share.materialsCount ? getFormattedWaste(share.materialsCount) : '0',
          },
          {
            label: `${getFormattedShare(share.materialsShare)}%`,
          },
        ],
      })) || [],
  }));
};

export const getChartData = (content, isContainers, title) => {
  return [
    ['Name', 'Waste', { type: 'string', role: 'tooltip', p: { html: true } }],
    ...content.map((item) => {
      const formattedValue = getFormattedWaste(item.value);
      return [
        item.material,
        formattedValue,

        `<div class="chart-tooltip"><span>${item.material}</span>${`<div>${
          isContainers ? 'Count' : title
        }: <b>${formattedValue} (${getFormattedShare(item.share)} %)</b></div>`}</div>`,
      ];
    }),
  ];
};

export const getChartDataByKey = (content, key, title) => {
  return [
    ['Name', 'Waste', { type: 'string', role: 'tooltip', p: { html: true } }],
    ...content.map((item) => {
      const formattedValue = getFormattedWaste(item[key]);
      return [
        item.material,
        formattedValue,

        `<div class="chart-tooltip"><span>${
          item.material
        }</span>${`<div>${title}: <b>${formattedValue}</b></div>`}</div>`,
      ];
    }),
  ];
};

export const getEmptyChartData = () => {
  return [
    ['Name', ''],
    ['Empty', 1],
  ];
};

export const formatSecondsToMinutes = (value) => {
  const minutes = value / 60;

  return parseFloat(minutes.toFixed(2));
};

export const formatMsToMinutes = (value) => {
  const minutes = value / 1000 / 60;

  return parseFloat(minutes.toFixed(2));
};

export const formatMsToSeconds = (value, fixed = 2) => {
  const minutes = value / 1000;

  return parseFloat(minutes.toFixed(fixed));
};

export const withAdditionalRowsFryerCycles = (rows) => {
  let maxTotal;
  let minTotal;
  let maxPerHour;
  let minPerHour;
  let sumTotal = 0;
  let sumPerHour = 0;

  const rowsCopy = [...rows];

  rows.forEach((row) => {
    if (isNil(maxTotal) || row.total > maxTotal) maxTotal = row.total;
    if (isNil(minTotal) || row.total < minTotal) minTotal = row.total;
    if (isNil(maxPerHour) || row.perHour > maxPerHour) maxPerHour = row.perHour;
    if (isNil(minPerHour) || row.perHour < minPerHour) minPerHour = row.perHour;
    sumTotal += row.total;
    sumPerHour += row.perHour;
  });

  const avgTotal = sumTotal / rows.length;
  const avgPerHour = sumPerHour / rows.length;

  rowsCopy.push({
    material: 'High Value',
    total: maxTotal,
    perHour: maxPerHour,
    additional: true,
  });

  rowsCopy.push({
    material: 'Low Value',
    total: minTotal,
    perHour: minPerHour,
    additional: true,
  });

  rowsCopy.push({
    material: 'Average Value',
    total: avgTotal,
    perHour: avgPerHour,
    additional: true,
  });

  return rowsCopy;
};
