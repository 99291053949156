import moment from 'moment';

import { getAnalytic, getDashboardReport } from '../../api/dashboardAnalytics';
import {
  dayPeriod,
  getBoxPlotValues,
  getColor,
  getColorRange,
  getDateFormatForPeriod,
  getDayPart,
  getHoursInDayPart,
} from '../../utils/chartHelper';
import { handleActionAbortController } from '../../utils/handleActionAbortController';
import { getStockOutTotal, getAnalytics as getStockOutAnalytics } from '../../api/stockOutReports';
import { downloadFileWithName } from '../../utils';

export const CHANGE_CHICKEN_DASHBOARD_VALUE = 'CHANGE_CHICKEN_DASHBOARD_VALUE';
export const CHANGE_CHICKEN_DASHBOARD_LOADING_VALUE = 'CHANGE_CHICKEN_DASHBOARD_LOADING_VALUE';
export const CHANGE_CHICKEN_DASHBOARD_SEVERAL_VALUES = 'CHANGE_CHICKEN_DASHBOARD_SEVERAL_VALUES';
export const RESET_CHICKEN_DASHBOARD_STORE = 'RESET_CHICKEN_DASHBOARD_STORE';
export const RESET_CHICKEN_CHARTS_DATA = 'GET_CHICKEN_CHARTS_DATA';

export const ADD_CHICKEN_CHART = 'ADD_CHICKEN_CHART';
export const GET_CHICKEN_CHART = 'GET_CHICKEN_CHART';

const chartsName = {
  summaryHTR: 'summaryHTR',
  wasteSummary: 'wasteSummary',
  detailedHoldingTime: 'detailedHoldingTime',
  detailedWaste: 'detailedWaste',
  holdTimeDistr: 'holdTimeDistr',
  averageHoldTime: 'averageHoldTime',
  wasteByDayPart: 'wasteByDayPart',
  panCyclesAverage: 'panCyclesAverage',
  panCycles: 'panCycles',
};

export const getSummaryCharts = (params, charts, materials) => {
  return async (dispatch) => {
    try {
      if (!charts.includes(chartsName.summaryHTR)) dispatch(getSummaryHTR(params, 1, materials));
      if (!charts.includes(chartsName.wasteSummary)) dispatch(getWasteSummary(params, 2, materials));
    } catch {
      //empty block
      () => undefined;
    }
  };
};

export const getDetailedTrendCharts = (params, charts, materials) => {
  return async (dispatch) => {
    try {
      if (!charts.includes(chartsName.detailedHoldingTime))
        dispatch(getDetailedHoldingTime(params, 1, materials));
      if (!charts.includes(chartsName.detailedWaste)) dispatch(getDetailedWaste(params, 2, materials));
    } catch {
      //empty block
      () => undefined;
    }
  };
};

export const getHoldTimeCharts = (params, charts, materials) => {
  return async (dispatch) => {
    try {
      if (!charts.includes(chartsName.summaryHTR)) dispatch(getSummaryHTR(params, 1, materials));
      if (!charts.includes(chartsName.detailedHoldingTime))
        dispatch(getDetailedHoldingTime(params, 2, materials));
      if (!charts.includes(chartsName.averageHoldTime)) dispatch(getAverageHoldTime(params, 3, materials));
      if (!charts.includes(chartsName.holdTimeDistr)) dispatch(getHoldTimeDistribution(params, 4));
    } catch {
      //empty block
      () => undefined;
    }
  };
};

export const getWasteCharts = (params, charts, materials) => {
  return async (dispatch) => {
    try {
      if (!charts.includes(chartsName.detailedWaste)) dispatch(getDetailedWaste(params, 1, materials));
      if (!charts.includes(chartsName.wasteByDayPart))
        dispatch(getWasteByDayPartAndProduct(params, 2, materials));
    } catch {
      //empty block
      () => undefined;
    }
  };
};

export const getProcessMetricCharts = (params, charts, materials) => {
  return async (dispatch) => {
    try {
      if (!charts.includes(chartsName.panCycles)) dispatch(getPanCyclesNoFilters(params, 1, materials));
      if (!charts.includes(chartsName.panCyclesAverage))
        dispatch(getPanCyclesNoFiltersAverage(params, 2, materials));
    } catch {
      //empty block
      () => undefined;
    }
  };
};

export const getSummaryHTR = (function () {
  let prevController;

  return (params, order, materials) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.summaryHTR });
        const response = await getAnalytic('/hdtbyproduct', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          const data = results.data;
          const rowsData = [];

          Object.keys(data).forEach((key) => {
            data[key].length === 2 && data[key].push(data[key][1], data[key][1]);
            data[key].length === 1 && data[key].push(data[key][0], data[key][0], data[key][0]);
            data[key].length === 3 && data[key].push(data[key][1], data[key][2]);

            rowsData.push([key, ...data[key], getColor(key, materials)]);
          });

          let maxLength = 0;
          rowsData.forEach((item) => {
            item.length > maxLength && (maxLength = item.length);
          });

          let chartColumns = [];
          chartColumns.push({ type: 'string', label: 'x' });
          for (let i = 2; i < maxLength; i++) {
            chartColumns.push({ type: 'number', label: null });
          }

          chartColumns = [
            ...chartColumns,
            { type: 'string', role: 'style' },
            { id: 'max', type: 'number', role: 'interval' },
            { id: 'min', type: 'number', role: 'interval' },
            { id: 'firstQuartile', type: 'number', role: 'interval' },
            { id: 'median', type: 'number', role: 'interval' },
            { id: 'thirdQuartile', type: 'number', role: 'interval' },
            { type: 'string', role: 'tooltip' },
          ];

          const chartRows = getBoxPlotValues(rowsData);

          const chartOptions = {
            titleTextStyle: {
              color: '#68605a',
            },
            enableInteractivity: false,
            legend: { position: 'none' },
            focusTarget: 'category',
            tooltip: { isHtml: true },
            hAxis: {
              title: 'Materials',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            vAxis: {
              title: 'Hold time in minutes',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            chartArea: { top: '0px', height: '100%' },
            gridlines: { color: '#fff' },
            lineWidth: 0,
            series: [{ color: '#D3362D' }],
            intervals: {
              barWidth: 1,
              boxWidth: 1,
              lineWidth: 2,
              style: 'boxes',
            },
            interval: {
              max: {
                style: 'bars',
                fillOpacity: 1,
                color: '#777',
              },
              min: {
                style: 'bars',
                fillOpacity: 1,
                color: '#777',
              },
            },
          };

          const chart = {
            groupTitle: 'Hold Time Distribution by Product',
            order: order,
            chartType: 'LineChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '1' },
          };
          dispatch(addChart(chart, chartsName.summaryHTR));
        }
      } catch {
        if (!abortController.signal.aborted) {
          const chart = {
            groupTitle: 'Hold Time Distribution by Product',
            rows: [],
            options: {},
          };
          dispatch(addChart(chart, chartsName.summaryHTR));
        }
      }
    };
  };
})();

export const getWasteSummary = (function () {
  let prevController;

  return (params, order, materials) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.wasteSummary });
        const response = await getAnalytic('/waste-result', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          const data = results.data;
          const rowsData = [];

          Object.keys(data).forEach((key) => {
            rowsData.push([key, data[key] / 453.593, getColor(key, materials)]);
          });

          let chartColumns = [
            { type: 'string', label: 'Element' },
            { type: 'number', label: 'Pounds' },
            { role: 'style' },
          ];
          const chartRows = rowsData;

          const chartOptions = {
            titleTextStyle: {
              color: '#68605a',
            },
            hAxis: {
              title: 'Materials',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            vAxis: {
              title: 'Waste in Pounds',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            bar: { groupWidth: '95%' },
            legend: { position: 'none' },
          };

          const chart = {
            groupTitle: 'Waste Result',
            order: order,
            chartType: 'ColumnChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '2' },
          };
          dispatch(addChart(chart, chartsName.wasteSummary));
        }
      } catch {
        if (!abortController.signal.aborted) {
          const chart = {
            groupTitle: 'Waste Result',
            rows: [],
            options: {},
          };
          dispatch(addChart(chart, chartsName.wasteSummary));
        }
      }
    };
  };
})();

export const getWasteByDayPartAndProduct = (function () {
  let prevController;

  return (params, order, materialNames) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.wasteByDayPart });
        const response = await getAnalytic('/waste-by-daypart-product', params, abortController.signal);
        const { success, results } = response;
        if (success) {
          const data = results.data;
          const materials = Object.keys(data);

          const rowsData = [];
          rowsData[0] = [];
          rowsData[1] = [];
          rowsData[2] = [];

          materials.forEach((material) => {
            rowsData[0].push(data[material][dayPeriod[0].name]);
            rowsData[1].push(data[material][dayPeriod[1].name]);
            rowsData[2].push(data[material][dayPeriod[2].name]);
          });

          let chartColumns = ['Chicken Type', ...materials, { role: 'annotation' }];
          const chartRows = [
            [dayPeriod[0].name, ...rowsData[0], ''],
            [dayPeriod[1].name, ...rowsData[1], ''],
            [dayPeriod[2].name, ...rowsData[2], ''],
          ];

          const chartOptions = {
            titleTextStyle: {
              color: '#68605a',
              fontName: 'Roboto',
              fontSize: '20pt',
            },
            hAxis: {
              title: 'Day Part',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            vAxis: {
              title: 'WASTE (IN DOLLARS)',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            legend: { position: 'bottom', maxLines: 3 },
            bar: { groupWidth: '75%' },
            isStacked: true,
            series: {
              ...materials.map((material) => {
                return { color: getColor(material, materialNames) };
              }),
            },
          };

          const chart = {
            groupTitle: 'Waste by Products & Daypart',
            order: order,
            chartType: 'ColumnChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '2' },
          };
          dispatch(addChart(chart, chartsName.wasteByDayPart));
        }
      } catch {
        const chart = {
          groupTitle: 'Waste by Products & Daypart',
          rows: [],
          options: {},
        };
        dispatch(addChart(chart, chartsName.wasteByDayPart));
      }
    };
  };
})();

export const getDetailedHoldingTime = (function () {
  let prevController;

  return (params, order, materialNames) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.detailedHoldingTime });
        const response = await getAnalytic('/detailed-hold-time', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          const data = results.data;
          const materials = results.materials;
          const chartRows = [];

          Object.keys(data).forEach((key) => {
            materials.forEach((material) => {
              data[key][material] = !!data[key][material] ? data[key][material] : [];
            });
          });

          Object.keys(data).forEach((key, idx) => {
            chartRows[idx] = [];
            chartRows[idx].push(params.analyticPeriod === 'MONTH' ? key : key.slice(5));

            materials.forEach((material) => {
              let avgHold = 0;
              if (data[key][material].length > 0) {
                data[key][material].forEach((holdTime) => {
                  avgHold += holdTime;
                });
                avgHold = avgHold / data[key][material].length;
              } else {
                avgHold = 0;
              }

              chartRows[idx].push(avgHold);
            });
          });

          let chartColumns = ['Time', ...materials];

          const chartOptions = {
            titleTextStyle: {
              color: '#68605a',
              fontName: 'Roboto',
              fontSize: '20pt',
            },
            // pointsVisible: true,
            animation: {
              duration: 10,
              startup: true,
            },
            hAxis: {
              title: 'Period',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            vAxis: {
              title: 'Average Hold time in minutes',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            interpolateNulls: true,
            series: {
              ...materials.map((material) => {
                return { color: getColor(material, materialNames) };
              }),
            },
          };

          const chart = {
            groupTitle: 'Average Hold Time Trend',
            order: order,
            chartType: 'LineChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '1' },
          };
          dispatch(addChart(chart, chartsName.detailedHoldingTime));
        }
      } catch {
        if (!abortController.signal.aborted) {
          const chart = {
            groupTitle: 'Average Hold Time Trend',
            rows: [],
            options: {},
          };
          dispatch(addChart(chart, chartsName.detailedHoldingTime));
        }
      }
    };
  };
})();

export const getDetailedWaste = (function () {
  let prevController;

  return (params, order, materialNames) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.detailedWaste });
        const response = await getAnalytic('/detailed-waste', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          const data = results.data;
          const materials = results.materials;
          const chartRows = [];

          Object.keys(data).forEach((key) => {
            materials.forEach((material) => {
              data[key][material] = !!data[key][material] ? data[key][material] : 0.0;
            });
          });

          Object.keys(data).forEach((key, idx) => {
            chartRows[idx] = [];
            chartRows[idx].push(params.analyticPeriod === 'MONTH' ? key : key.slice(5));

            materials.forEach((material) => {
              chartRows[idx].push(data[key][material]);
            });

            chartRows[idx].push('');
          });

          let chartColumns = ['Chicken Type', ...materials, { role: 'annotation' }];

          const chartOptions = {
            titleTextStyle: {
              color: '#68605a',
              fontName: 'Roboto',
              fontSize: '20pt',
            },
            hAxis: {
              title: 'Period',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            vAxis: {
              title: 'WASTE (IN DOLLARS)',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            legend: { position: 'bottom', maxLines: 3 },
            bar: { groupWidth: '75%' },
            isStacked: true,
            series: {
              ...materials.map((material) => {
                return { color: getColor(material, materialNames) };
              }),
            },
          };

          const chart = {
            groupTitle: 'Waste Trend',
            order: order,
            chartType: 'ColumnChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '2' },
          };
          dispatch(addChart(chart, chartsName.detailedWaste));
        }
      } catch {
        if (!abortController.signal.aborted) {
          const chart = {
            groupTitle: 'Waste Trend',
            rows: [],
            options: {},
          };
          dispatch(addChart(chart, chartsName.detailedWaste));
        }
      }
    };
  };
})();

export const getHoldTimeDistribution = (function () {
  let prevController;

  return (params, order) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.holdTimeDistr });
        const response = await getAnalytic('/hold-time-distribution', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          const data = results.data;
          const timeRanges = results.timeRange;
          const chartRows = [];

          Object.keys(data).forEach((key) => {
            timeRanges.forEach((range) => {
              data[key][range] = !!data[key][range] ? data[key][range] : 0.0;
            });
          });

          Object.keys(data).forEach((key, idx) => {
            chartRows[idx] = [];
            chartRows[idx].push(params.analyticPeriod === 'MONTH' ? key : key.slice(5));

            timeRanges.forEach((range) => {
              chartRows[idx].push(data[key][range]);
            });

            chartRows[idx].push('');
          });

          let chartColumns = ['Time range', ...timeRanges, { role: 'annotation' }];

          const chartOptions = {
            titleTextStyle: {
              color: '#68605a',
              fontName: 'Roboto',
              fontSize: '20pt',
            },
            hAxis: {
              title: 'Period',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            vAxis: {
              title: 'Percent',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
              viewWindowMode: 'pretty',
              viewWindow: {
                max: 100,
              },
            },
            legend: { position: 'bottom', maxLines: 3 },
            bar: { groupWidth: '75%' },
            isStacked: true,
            series: {
              ...timeRanges.map((range) => {
                return { color: getColorRange(range) };
              }),
            },
          };

          const chart = {
            groupTitle: 'Hold Time Distribution',
            order: order,
            chartType: 'ColumnChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '4' },
          };
          dispatch(addChart(chart, chartsName.holdTimeDistr));
        }
      } catch {
        if (!abortController.signal.aborted) {
          const chart = {
            groupTitle: 'Hold Time Distribution',
            rows: [],
            options: {},
          };
          dispatch(addChart(chart, chartsName.holdTimeDistr));
        }
      }
    };
  };
})();

export const getAverageHoldTime = (function () {
  let prevController;

  return (params, order, materialNames) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.averageHoldTime });
        const response = await getAnalytic('/average-hold-time', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          const timersData = results.data.timers;
          const chartRows = [];

          const allKeys = {};
          const allMonths = {};
          const allWeeks = {};
          const allDays = {};
          const allDayParts = {
            'Breakfast - Before 10:30am': null,
            'Lunch - 10:30am - 2pm': null,
            'Dinner - After 2pm': null,
          };
          const allHours = {};
          let allStructure = {};

          timersData
            .filter((timer) => {
              return (
                !!timer.endQuantity &&
                !!timer.startQuantity &&
                timer.startQuantity - timer.endQuantity > 0 &&
                timer.timeInChute / 60000 <= 60 &&
                timer.timeInChute / 60000 > 1
              );
            })
            .forEach((timer) => {
              const date = moment(timer.eventTime, 'YYYY-MM-DD').startOf('hour').format('YYYY-MM-DD');
              const month = moment(timer.eventTime, 'YYYY-MM-DD').startOf('hour').format('MMM');
              const week = moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').week();
              const hour = moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').format('HH');
              allMonths[month] = null;
              allWeeks[week] = null;
              allDays[date] = null;
              allHours[hour] = null;
              allKeys[timer.material] = 0;
            });

          allStructure = { ...allMonths };
          const delimiterCount = {};
          Object.keys(allDayParts).forEach((dayPart) => {
            delimiterCount[dayPart] = {};
          });

          timersData
            .filter((timer) => {
              return (
                !!timer.endQuantity &&
                !!timer.startQuantity &&
                timer.startQuantity - timer.endQuantity > 0 &&
                timer.timeInChute / 60000 <= 60 &&
                timer.timeInChute / 60000 > 1
              );
            })
            .forEach((timer) => {
              const date = moment(timer.eventTime, 'YYYY-MM-DD').startOf('hour').format('YYYY-MM-DD');
              const month = moment(timer.eventTime, 'YYYY-MM-DD').startOf('hour').format('MMM');
              const week = moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').week();
              const hour = moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').format('HH');
              const dayPart = getDayPart(timer.eventTime);
              allStructure[month] = allStructure[month] ? allStructure[month] : { ...allWeeks };
              allStructure[month][week] = allStructure[month][week]
                ? allStructure[month][week]
                : { ...allDays };
              allStructure[month][week][date] = allStructure[month][week][date]
                ? allStructure[month][week][date]
                : { ...allDayParts };
              allStructure[month][week][date][dayPart] = allStructure[month][week][date][dayPart]
                ? allStructure[month][week][date][dayPart]
                : { ...allHours };
              allStructure[month][week][date][dayPart][hour] = allStructure[month][week][date][dayPart][hour]
                ? allStructure[month][week][date][dayPart][hour]
                : { ...allKeys };
              allStructure[month][week][date][dayPart][hour][timer.material] += timer.timeInChute / 60000;
              delimiterCount[dayPart][timer.material] = delimiterCount[dayPart][timer.material]
                ? delimiterCount[dayPart][timer.material]
                : 0;
              delimiterCount[dayPart][timer.material] += 1;
            });

          const dataMap = {};
          const dataMapFull = {};
          // TODO: Range limit. one year? If not - add year structure
          Object.keys(allStructure).forEach((mon) => {
            if (!!allStructure[mon]) {
              Object.keys(allStructure[mon]).forEach((week) => {
                if (!!allStructure[mon][week]) {
                  Object.keys(allStructure[mon][week]).forEach((day) => {
                    if (!!allStructure[mon][week][day]) {
                      Object.keys(allStructure[mon][week][day]).forEach((part) => {
                        if (!!allStructure[mon][week][day][part]) {
                          Object.keys(allStructure[mon][week][day][part]).forEach((hour) => {
                            if (!!allStructure[mon][week][day][part][hour]) {
                              Object.keys(allStructure[mon][week][day][part][hour]).forEach((material) => {
                                dataMapFull[part] = dataMapFull[part] ? dataMapFull[part] : { ...allKeys };
                                dataMapFull[part][material] +=
                                  allStructure[mon][week][day][part][hour][material];
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });

          let maxNumber = 0;
          Object.keys(dataMapFull).forEach((dayPart) => {
            Object.keys(dataMapFull[dayPart]).forEach((material) => {
              dataMap[dayPart] = dataMap[dayPart] ? dataMap[dayPart] : { ...allKeys };
              dataMap[dayPart][material] = dataMapFull[dayPart][material] / delimiterCount[dayPart][material];
              if (dataMap[dayPart][material] > maxNumber) {
                maxNumber = dataMap[dayPart][material];
              }
            });
          });
          maxNumber < 20 && (maxNumber = 20);

          const ticksArr = [];
          for (let i = 0; i <= maxNumber + 1; ) {
            ticksArr.push(i);
            i += 4;
          }

          Object.keys(dataMap)
            .sort((a, b) => {
              if (Number(a) < Number(b)) {
                return -1;
              }
              if (Number(a) > Number(b)) {
                return 1;
              }
              return 0;
            })
            .forEach((key, idx) => {
              chartRows[idx] = [];
              chartRows[idx].push(key);
              Object.keys(allKeys).forEach((k) => {
                chartRows[idx].push(dataMap[key][k]);
              });
              chartRows[idx].push('');
            });

          let chartColumns = ['Chicken Type', ...Object.keys(allKeys), { role: 'annotation' }];

          const chartOptions = {
            titleTextStyle: {
              color: '#68605a',
              fontName: 'Roboto',
              fontSize: '20pt',
            },
            vAxis: {
              title: 'Minutes',
              ticks: [...ticksArr],
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
              gridlines: { color: '#cbcbcb' },
            },
            hAxis: {
              title: 'Day part',
              showLastValue: true,
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            legend: { position: 'bottom', maxLines: 3 },
            bar: { groupWidth: '75%' },
            // isStacked: true,
            series: {
              ...Object.keys(allKeys).map((key) => {
                return { color: getColor(key, materialNames) };
              }),
            },
          };

          const chart = {
            groupTitle: 'Average Hold Time by Product & Daypart',
            order: order,
            chartType: 'ColumnChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '3' },
          };
          dispatch(addChart(chart, chartsName.averageHoldTime));
        }
      } catch {
        if (!abortController.signal.aborted) {
          const chart = {
            groupTitle: 'Average Hold Time by Product & Daypart',
            rows: [],
            options: {},
          };
          dispatch(addChart(chart, chartsName.averageHoldTime));
        }
      }
    };
  };
})();

export const getPanCyclesNoFiltersAverage = (function () {
  let prevController;

  return (params, order, materialNames) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.panCyclesAverage });
        const response = await getAnalytic('/average-hold-time', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          const timersData = results.data.timers;
          const chartRows = [];

          const allKeys = {};
          const allMonths = {};
          const allWeeks = {};
          const allDays = {};
          const allDayParts = {};
          const allHours = {};
          let allStructure = {};

          dayPeriod.forEach((part) => {
            allDayParts[part.name] = null;
          });

          timersData.forEach((timer) => {
            const date = moment(timer.eventTime, 'YYYY-MM-DD').startOf('hour').format('YYYY-MM-DD');
            const month = moment(timer.eventTime, 'YYYY-MM-DD').startOf('hour').format('MMM');
            const week = moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').week();
            const hour = moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').format('HH');
            allMonths[month] = null;
            allWeeks[week] = null;
            allDays[date] = null;
            allHours[hour] = null;
            allKeys[timer.material] = 0;
          });

          allStructure = { ...allMonths };

          timersData.forEach((timer) => {
            const date = moment(timer.eventTime, 'YYYY-MM-DD').startOf('hour').format('YYYY-MM-DD');
            const month = moment(timer.eventTime, 'YYYY-MM-DD').startOf('hour').format('MMM');
            const week = moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').week();
            const hour = moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').format('HH');
            const dayPart = getDayPart(timer.eventTime);
            allStructure[month] = allStructure[month] ? allStructure[month] : { ...allWeeks };
            allStructure[month][week] = allStructure[month][week]
              ? allStructure[month][week]
              : { ...allDays };
            allStructure[month][week][date] = allStructure[month][week][date]
              ? allStructure[month][week][date]
              : { ...allDayParts };
            allStructure[month][week][date][dayPart] = allStructure[month][week][date][dayPart]
              ? allStructure[month][week][date][dayPart]
              : { ...allHours };
            allStructure[month][week][date][dayPart][hour] = allStructure[month][week][date][dayPart][hour]
              ? allStructure[month][week][date][dayPart][hour]
              : { ...allKeys };
            allStructure[month][week][date][dayPart][hour][timer.material] += 1;
          });

          const dataMap = {};
          const dataMapFull = {};
          const dayPartDelim = {};
          // TODO: Range limit. one year? If not - add year structure
          Object.keys(allStructure).forEach((mon) => {
            if (!!allStructure[mon]) {
              Object.keys(allStructure[mon]).forEach((week) => {
                if (!!allStructure[mon][week]) {
                  Object.keys(allStructure[mon][week]).forEach((day) => {
                    if (!!allStructure[mon][week][day]) {
                      Object.keys(allStructure[mon][week][day]).forEach((part) => {
                        dayPartDelim[part] = dayPartDelim[part] ? dayPartDelim[part] : 0;
                        if (!!allStructure[mon][week][day][part]) {
                          dayPartDelim[part] += Object.keys(allStructure[mon][week][day][part]).filter(
                            (fkey) => {
                              return !!allStructure[mon][week][day][part][fkey];
                            }
                          ).length;
                          Object.keys(allStructure[mon][week][day][part]).forEach((hour) => {
                            if (!!allStructure[mon][week][day][part][hour]) {
                              Object.keys(allStructure[mon][week][day][part][hour]).forEach((material) => {
                                dataMapFull[part] = dataMapFull[part] ? dataMapFull[part] : { ...allKeys };
                                dataMapFull[part][material] +=
                                  allStructure[mon][week][day][part][hour][material];
                              });
                            }
                          });
                        }
                      });
                    }
                  });
                }
              });
            }
          });

          let maxNumber = 0;
          const days =
            moment(params.endTime, 'YYYY-MM-DD')
              .startOf('day')
              .diff(moment(params.startTime, 'YYYY-MM-DD').startOf('day'), 'days') + 1;

          Object.keys(dataMapFull).forEach((dayPart) => {
            Object.keys(dataMapFull[dayPart]).forEach((material) => {
              dataMap[dayPart] = dataMap[dayPart] ? dataMap[dayPart] : { ...allKeys };
              dataMap[dayPart][material] = dataMapFull[dayPart][material] / days / getHoursInDayPart(dayPart);
              if (dataMap[dayPart][material] > maxNumber) {
                maxNumber = dataMap[dayPart][material];
              }
            });
          });
          maxNumber < 5 && (maxNumber = 5);

          const ticksArr = [];
          for (let i = 0; i <= maxNumber + 0.5; ) {
            ticksArr.push(i);
            i += 1;
          }

          Object.keys(dataMap)
            .sort((a, b) => {
              if (Number(a) < Number(b)) {
                return -1;
              }
              if (Number(a) > Number(b)) {
                return 1;
              }
              return 0;
            })
            .forEach((key, idx) => {
              chartRows[idx] = [];
              chartRows[idx].push(key);
              Object.keys(allKeys).forEach((k) => {
                chartRows[idx].push(dataMap[key][k]);
              });
              chartRows[idx].push('');
            });

          let chartColumns = ['Chicken Type', ...Object.keys(allKeys), { role: 'annotation' }];

          const chartOptions = {
            titleTextStyle: {
              color: '#68605a',
              fontName: 'Roboto',
              fontSize: '20pt',
            },
            vAxis: {
              title: 'Average per hour',
              ticks: [...ticksArr],
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
              gridlines: { color: '#cbcbcb' },
            },
            hAxis: {
              title: 'Day part',
              showLastValue: true,
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            legend: { position: 'bottom', maxLines: 3 },
            bar: { groupWidth: '75%' },
            // isStacked: true,
            series: {
              ...Object.keys(allKeys).map((key) => {
                return { color: getColor(key, materialNames) };
              }),
            },
          };

          const chart = {
            groupTitle: "'Average Pan Cycles per Hour with no filters - by Daypart & Product'",
            order: order,
            chartType: 'ColumnChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '3' },
          };
          dispatch(addChart(chart, chartsName.panCyclesAverage));
        }
      } catch {
        if (!abortController.signal.aborted) {
          const chart = {
            groupTitle: 'Average Pan Cycles per Hour with no filters - by Daypart & Product',
            rows: [],
            options: {},
          };
          dispatch(addChart(chart, chartsName.panCyclesAverage));
        }
      }
    };
  };
})();

export const getPanCyclesNoFilters = (function () {
  let prevController;

  return (params, order, materialNames) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CHICKEN_CHART, chartName: chartsName.panCycles });
        const response = await getAnalytic('/average-hold-time', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          const timersData = results.data.timers;
          const chartRows = [];

          const allKeys = {};
          results.materials.forEach((material) => {
            allKeys[material] = 0;
          });

          const dataMap = {};
          timersData.forEach((timer) => {
            let date = moment(timer.eventTime, 'YYYY-MM-DD HH:mm')
              .startOf('hour')
              .format(getDateFormatForPeriod(params.analyticPeriod));
            if (params.analyticPeriod === 'DAY_PART') {
              date += ' ' + getDayPart(timer.eventTime);
            }
            if (params.analyticPeriod === 'WEEK') {
              date += ' Week #' + moment(timer.eventTime, 'YYYY-MM-DD HH:mm').startOf('hour').week();
            }
            dataMap[date] = dataMap[date] ? dataMap[date] : { ...allKeys };
            dataMap[date][timer.material] += 1;
          });
          let maxNumber = 0;

          Object.keys(dataMap).forEach((period) => {
            let max = 0;
            Object.keys(dataMap[period]).forEach((material) => {
              max += dataMap[period][material];
            });
            max > maxNumber && (maxNumber = max);
          });

          const ticksArr = [];

          let goal = 355;
          let goalMin = 215;
          let goalRange = 25;

          switch (params.analyticPeriod) {
            case 'MONTH':
              goal = goal * 30;
              goalMin = goalMin * 30;
              goalRange = goalRange * 30;
              break;
            case 'WEEK':
              goal = goal * 6;
              goalMin = goalMin * 6;
              goalRange = goalRange * 6;
              break;
            case 'DAY_PART':
              goal = Math.round(goal / dayPeriod.length);
              goalMin = Math.round(goalMin / dayPeriod.length);
              goalRange = Math.round(goalRange / dayPeriod.length);
              break;
            case 'DAY':
              goal = 355;
              goalMin = 215;
              goalRange = 25;
              break;
            case 'HOUR':
              goal = 5 * Object.keys(allKeys).length;
              goalMin = 5 * Object.keys(allKeys).length;
              goalRange = Object.keys(allKeys).length;
              break;
          }

          maxNumber < goal && (maxNumber = goal);

          let minAchieve = false;
          let goalAchieve = false;

          for (let i = 0; i <= maxNumber + goalRange; ) {
            ticksArr.push(i);
            i += goalRange > 0 ? goalRange : 1;

            if (i + goalRange > goalMin && !minAchieve) {
              ticksArr.push(goalMin);
              i = goalMin;
              minAchieve = true;
            }

            if (i + goalRange > goal && !goalAchieve) {
              ticksArr.push(goal);
              i = goal;
              goalAchieve = true;
            }
          }

          Object.keys(dataMap).forEach((key, idx) => {
            chartRows[idx] = [];
            chartRows[idx].push(params.analyticPeriod === 'MONTH' ? key : key.slice(5));
            Object.keys(allKeys).forEach((k) => {
              chartRows[idx].push(dataMap[key][k]);
            });
            chartRows[idx].push('');
          });

          let chartColumns = ['Chicken Type', ...Object.keys(allKeys), { role: 'annotation' }];

          const chartOptions = {
            annotations: {
              textStyle: {
                color: '#000000',
              },
            },
            colors: ['#000000'],
            titleTextStyle: {
              color: '#68605a',
              fontName: 'Roboto',
              fontSize: '20pt',
            },
            vAxis: {
              title: 'Number of Cycles',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
              ticks: [...ticksArr],
              gridlines: { color: '#cbcbcb' },
            },
            hAxis: {
              title: 'Period',
              titleTextStyle: {
                color: '#68605a',
                fontName: 'Roboto',
                fontSize: '20pt',
              },
            },
            legend: { position: 'bottom', maxLines: 3 },
            bar: { groupWidth: '75%' },
            isStacked: true,
            series: {
              ...Object.keys(allKeys).map((key) => {
                return { color: getColor(key, materialNames) };
              }),
            },
          };

          const chart = {
            groupTitle: 'Pan Cycles with no filters',
            order: order,
            chartType: 'ColumnChart',
            columns: chartColumns,
            rows: chartRows,
            options: chartOptions,
            rootProps: { 'data-testid': '3' },
          };
          dispatch(addChart(chart, chartsName.panCycles));
        }
      } catch {
        if (!abortController.signal.aborted) {
          const chart = {
            groupTitle: 'Pan Cycles with no filters',
            rows: [],
            options: {},
          };
          dispatch(addChart(chart, chartsName.panCycles));
        }
      }
    };
  };
})();

export const resetCharts = () => ({
  type: RESET_CHICKEN_CHARTS_DATA,
});

export const changeLoadingValue = (value = []) => ({
  type: CHANGE_CHICKEN_DASHBOARD_LOADING_VALUE,
  payload: value,
});

const addChart = (chart, chartName) => {
  return {
    type: ADD_CHICKEN_CHART,
    chart,
    chartName,
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CHICKEN_DASHBOARD_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CHICKEN_DASHBOARD_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_CHICKEN_DASHBOARD_STORE,
});

//////// New api
export const GET_TOTAL_USAGE = 'GET_TOTAL_USAGE';
export const ADD_TOTAL_USAGE = 'ADD_TOTAL_USAGE';

export const GET_COOKED_CHART_DATA = 'GET_COOKED_CHART_DATA';
export const ADD_COOKED_CHART_DATA = 'ADD_COOKED_CHART_DATA';
export const GET_COOKED_DISTRIBUTION_DATA = 'GET_COOKED_DISTRIBUTION_DATA';
export const ADD_COOKED_DISTRIBUTION_DATA = 'ADD_COOKED_DISTRIBUTION_DATA';

export const GET_COOKED_CATERING_CHART_DATA = 'GET_COOKED_CATERING_CHART_DATA';
export const ADD_COOKED_CATERING_CHART_DATA = 'ADD_COOKED_CATERING_CHART_DATA';
export const GET_COOKED_CATERING_DISTRIBUTION_DATA = 'GET_COOKED_CATERING_DISTRIBUTION_DATA';
export const ADD_COOKED_CATERING_DISTRIBUTION_DATA = 'ADD_COOKED_CATERING_DISTRIBUTION_DATA';

export const GET_WASTE_CHART_DATA = 'GET_WASTE_CHART_DATA';
export const ADD_WASTE_CHART_DATA = 'ADD_WASTE_CHART_DATA';
export const GET_WASTE_DISTRIBUTION_DATA = 'GET_WASTE_DISTRIBUTION_DATA';
export const ADD_WASTE_DISTRIBUTION_DATA = 'ADD_WASTE_DISTRIBUTION_DATA';

export const GET_RECOMMENDATIONS_CHART_DATA = 'GET_RECOMMENDATIONS_CHART_DATA';
export const ADD_RECOMMENDATIONS_CHART_DATA = 'ADD_RECOMMENDATIONS_CHART_DATA';
export const GET_RECOMMENDATIONS_DISTRIBUTION_DATA = 'GET_RECOMMENDATIONS_DISTRIBUTION_DATA';
export const ADD_RECOMMENDATIONS_DISTRIBUTION_DATA = 'ADD_RECOMMENDATIONS_DISTRIBUTION_DATA';

export const GET_RECOMMENDATIONS_CATERING_CHART_DATA = 'GET_RECOMMENDATIONS_CATERING_CHART_DATA';
export const ADD_RECOMMENDATIONS_CATERING_CHART_DATA = 'ADD_RECOMMENDATIONS_CATERING_CHART_DATA';
export const GET_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA =
  'GET_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA';
export const ADD_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA =
  'ADD_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA';

export const GET_STOCK_OUT_CHART_DATA = 'GET_STOCK_OUT_CHART_DATA';
export const ADD_STOCK_OUT_CHART_DATA = 'ADD_STOCK_OUT_CHART_DATA';
export const GET_STOCK_OUT_DISTRIBUTION_DATA = 'GET_STOCK_OUT_DISTRIBUTION_DATA';
export const ADD_STOCK_OUT_DISTRIBUTION_DATA = 'ADD_STOCK_OUT_DISTRIBUTION_DATA';

export const GET_TASK_DELAY_CHART_DATA = 'GET_TASK_DELAY_CHART_DATA';
export const ADD_TASK_DELAY_CHART_DATA = 'ADD_TASK_DELAY_CHART_DATA';
export const GET_TASK_DELAY_DISTRIBUTION_DATA = 'GET_TASK_DELAY_DISTRIBUTION_DATA';
export const ADD_TASK_DELAY_DISTRIBUTION_DATA = 'ADD_TASK_DELAY_DISTRIBUTION_DATA';

export const GET_FRYER_CYCLES_CHART_DATA = 'GET_FRYER_CYCLES_CHART_DATA';
export const ADD_FRYER_CYCLES_CHART_DATA = 'ADD_FRYER_CYCLES_CHART_DATA';
export const GET_FRYER_CYCLES_DISTRIBUTION_DATA = 'GET_FRYER_CYCLES_DISTRIBUTION_DATA';
export const ADD_FRYER_CYCLES_DISTRIBUTION_DATA = 'ADD_FRYER_CYCLES_DISTRIBUTION_DATA';

export const GET_CONTAINERS_DISTRIBUTION_DATA = 'GET_CONTAINERS_DISTRIBUTION_DATA';
export const ADD_CONTAINERS_DISTRIBUTION_DATA = 'ADD_CONTAINERS_DISTRIBUTION_DATA';
export const GET_CONTAINERS_CATERING_DISTRIBUTION_DATA = 'GET_CONTAINERS_CATERING_DISTRIBUTION_DATA';
export const ADD_CONTAINERS_CATERING_DISTRIBUTION_DATA = 'ADD_CONTAINERS_CATERING_DISTRIBUTION_DATA';

export const GET_TIMERS_CHART_DATA = 'GET_TIMERS_CHART_DATA';
export const SET_TIMERS_CHART_DATA = 'SET_TIMERS_CHART_DATA';

export const GET_PERFORMANCE_RATIO_DATA = 'GET_PERFORMANCE_RATIO_DATA';
export const SET_PERFORMANCE_RATIO_DATA = 'SET_PERFORMANCE_RATIO_DATA';

export const LOAD_CHICKEN_DASHBOARD_FILE = 'LOAD_CHICKEN_DASHBOARD_FILE';
export const LOAD_CHICKEN_DASHBOARD_FILE_SUCCESS = 'LOAD_CHICKEN_DASHBOARD_FILE_SUCCESS';
export const LOAD_CHICKEN_DASHBOARD_FILE_ERROR = 'LOAD_CHICKEN_DASHBOARD_FILE_ERROR';

export const SET_CHICKEN_DASHBOARD_SORT_BY = 'SET_CHICKEN_DASHBOARD_SORT_BY';
export const SET_CHICKEN_DASHBOARD_ORDER_DIRECTION = 'SET_CHICKEN_DASHBOARD_ORDER_DIRECTION';
export const SET_CHICKEN_DASHBOARD_SECTION_TAB = 'SET_CHICKEN_DASHBOARD_SECTION_TAB';

export const downloadChickenDashboardReport = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_CHICKEN_DASHBOARD_FILE });
    try {
      const response = await getDashboardReport(params);
      const { success, results, headers } = response;
      if (success) {
        if (response.code !== 202) {
          const contentDisposition = headers['content-disposition'];
          const fileName = contentDisposition.split(';')[1].split('=')[1];
          downloadFileWithName(results, 'application/zip', fileName);
        }
        dispatch({ type: LOAD_CHICKEN_DASHBOARD_FILE_SUCCESS });
        if (actions.onSuccess) {
          actions.onSuccess(response);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_CHICKEN_DASHBOARD_FILE_ERROR });
      if (actions.onError) {
        actions.onError(error.message);
      }
    }
  };
};

export const getTotalUsage = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_TOTAL_USAGE });
        const response = await getAnalytic('/total-usage', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_TOTAL_USAGE, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_TOTAL_USAGE, payload: null });
        }
      }
    };
  };
})();

export const getCookedChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_COOKED_CHART_DATA });
        const response = await getAnalytic('/cooked', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_COOKED_CHART_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_COOKED_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getCookedCateringChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_COOKED_CATERING_CHART_DATA });
        const response = await getAnalytic('/catering-cooked', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_COOKED_CATERING_CHART_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_COOKED_CATERING_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getCookedDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_COOKED_DISTRIBUTION_DATA });
        const response = await getAnalytic('/cooked-distribution', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_COOKED_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_COOKED_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getCookedCateringDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_COOKED_CATERING_DISTRIBUTION_DATA });
        const response = await getAnalytic('/catering-cooked-distribution', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_COOKED_CATERING_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_COOKED_CATERING_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getWasteChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_WASTE_CHART_DATA });
        const response = await getAnalytic('/waste', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_WASTE_CHART_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_WASTE_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getWasteDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_WASTE_DISTRIBUTION_DATA });
        const response = await getAnalytic('/waste-distribution', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_WASTE_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_WASTE_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getRecommendationsChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_RECOMMENDATIONS_CHART_DATA });
        const response = await getAnalytic('/recommendations', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_RECOMMENDATIONS_CHART_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_RECOMMENDATIONS_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getRecommendationsDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_RECOMMENDATIONS_DISTRIBUTION_DATA });
        const response = await getAnalytic('/recommendations-distribution', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_RECOMMENDATIONS_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_RECOMMENDATIONS_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getRecommendationsCateringChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_RECOMMENDATIONS_CATERING_CHART_DATA });
        const response = await getAnalytic('/catering-recommendations', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_RECOMMENDATIONS_CATERING_CHART_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_RECOMMENDATIONS_CATERING_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getRecommendationsCateringDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA });
        const response = await getAnalytic(
          '/catering-recommendations-distribution',
          params,
          abortController.signal
        );
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getContainersDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CONTAINERS_DISTRIBUTION_DATA });
        const response = await getAnalytic('/containers-distribution', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_CONTAINERS_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_CONTAINERS_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getContainersCateringDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_CONTAINERS_CATERING_DISTRIBUTION_DATA });
        const response = await getAnalytic(
          '/catering-containers-distribution',
          params,
          abortController.signal
        );
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_CONTAINERS_CATERING_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_CONTAINERS_CATERING_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getFryerCyclesChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_FRYER_CYCLES_CHART_DATA });
        const response = await getAnalytic('/fryer-cycles', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_FRYER_CYCLES_CHART_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_FRYER_CYCLES_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getFryerCyclesDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_FRYER_CYCLES_DISTRIBUTION_DATA });
        const response = await getAnalytic('/fryer-cycles-distribution', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_FRYER_CYCLES_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_FRYER_CYCLES_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getStockOutChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_STOCK_OUT_CHART_DATA });
        const response = await getStockOutAnalytics(
          {
            restaurantsUuid: params.restaurantUuid,
            startTime: params.startTime,
            endTime: params.endTime,
            materials: params.materials,
            interval: params.intervalInSeconds,
          },
          abortController.signal
        );
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_STOCK_OUT_CHART_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_STOCK_OUT_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getStockOutDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_STOCK_OUT_DISTRIBUTION_DATA });
        const response = await getStockOutTotal(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_STOCK_OUT_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_STOCK_OUT_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getTaskDelayChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_TASK_DELAY_CHART_DATA });
        const response = await getAnalytic('/recommendations-task-delay', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_TASK_DELAY_CHART_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_TASK_DELAY_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getTaskDelayDistributionData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_TASK_DELAY_DISTRIBUTION_DATA });
        const response = await getAnalytic(
          '/recommendation-task-delay-distribution',
          params,
          abortController.signal
        );
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: ADD_TASK_DELAY_DISTRIBUTION_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: ADD_TASK_DELAY_DISTRIBUTION_DATA, payload: null });
        }
      }
    };
  };
})();

export const getTimersChartData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_TIMERS_CHART_DATA });
        const [response, responseExt] = await Promise.all([
          getAnalytic('/chicken-performance', params, abortController.signal),
          getAnalytic('/extended-chicken-performance', params, abortController.signal),
        ]);
        const { success, results } = response;
        const { success: successExt, results: resultsExt } = responseExt;

        if (success && successExt && !abortController.signal.aborted) {
          dispatch({ type: SET_TIMERS_CHART_DATA, payload: { chart: results, table: resultsExt } });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: SET_TIMERS_CHART_DATA, payload: null });
        }
      }
    };
  };
})();

export const getPerformanceRatioData = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      try {
        dispatch({ type: GET_PERFORMANCE_RATIO_DATA });
        const response = await getAnalytic('/performance-ratio', params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: SET_PERFORMANCE_RATIO_DATA, payload: results });
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: SET_PERFORMANCE_RATIO_DATA, payload: null });
        }
      }
    };
  };
})();

export const setSortBy = (section, sortBy) => ({
  type: SET_CHICKEN_DASHBOARD_SORT_BY,
  payload: {
    section,
    sortBy,
  },
});

export const setOrderDirection = (section, orderDirection) => ({
  type: SET_CHICKEN_DASHBOARD_ORDER_DIRECTION,
  payload: {
    section,
    orderDirection,
  },
});

export const setSectionTab = (section, tab) => ({
  type: SET_CHICKEN_DASHBOARD_SECTION_TAB,
  payload: {
    section,
    tab,
  },
});
